import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        width: "150px",
        height: "150px",
        margin: "15px 15px 0 0",
        alignSelf: "center",
      }}
    >
      <Image />
    </div>
    <h1>About me</h1>
    <p>
      I'm Javier Casaudoumecq: a Sr. Engineering Manager, father, video gamer,
      soccer player, horror movie loving, coffee-drinking, music-listening nerd.
    </p>
    <p>
      Currently Sr. Engineering Manager{" "}
      <a href="https://offerup.com/">@OfferUp</a>. Previously{" "}
      <a href="https://www.applicaster.com/">@Applicaster</a> and{" "}
      <a href="http://www.blim.com/">@blim</a>.{" "}
    </p>

    <p>
      For a significant part of my career, I have been working in the OTT space
      of the LATAM region. I have helped build platforms for major OTT companies
      such as Televisa, Azteca, Medcom, America TV, and more.{" "}
    </p>
  </Layout>
)

export default IndexPage
